<template lang="pug">
.content(
  v-infinite-scroll="fetchData",
  infinite-scroll-disabled="isTaskBusy",
  :infinite-scroll-distance="scrollDistance",
  infinite-scroll-immediate-check="false"
)
  Content.app-content
    Row
      Col(span='24')
        Select.app-tool(
          v-model="operator"
          style="width:140px"
          @on-change="onOperatorChange"
          filterable
        )
          Option(v-for="item in operatorOptions" :value="item.value" :key="item.value") {{ item.label }}

        Select.app-tool(
          v-model="game"
          style="width:140px"
          prefix="ios-game-controller-a"
          @on-change="onGameInputChange"
          filterable
        )
          Option(v-for="item in allGamesOptions" :value="item.value" :key="item.value") {{ item.label }}

        Select.app-tool(
          v-model="type"
          :placeholder="$t('Please select task type')"
          @on-change="onTaskTypeChange"
          filterable
        )
          Option(
            v-for="item in taskTypeOptions"
            :value="item.value"
            :key="`task-type-${item.value}`"
            ) {{ item.label}}

        Select.app-tool(
          v-model="category"
          :placeholder="$t('Please select task category')"
          @on-change="onTaskCategoryChange"
          filterable
        )
          Option(
            v-for="item in taskCategoryOptions"
            :value="item.value"
            :key="`task-cate-${item.value}`") {{ item.label}}

        Input.app-tool(
          v-model="search"
          icon="ios-search"
          clearable
          :placeholder="$t('Search name')"
          @on-change='onSearchInputChange'
        )

        Button.app-tool-right(
          v-if="taskType !== 'user'"
          type="primary",
          icon="md-add",
          @click="showTaskEditor"
        ) {{ $t('Add new task') }}

    //- 主表單內容
    Row(:style="{marginTop: '16px'}")
      Col(span='24' v-if="taskList.length || !taskListLoading")
        TaskTable(
          :list-data="taskList",
        )

      Col(span='24', class="table-loading-col", v-if="taskListLoading")
        Spin(fix)
          Icon(type="ios-loading", size=18 class="spin-icon")

    TaskEditor(
      :form-data="taskData"
      edit-type="new"
      :visible="taskEditorVisible"
      :on-update="onTaskCreate"
      :on-close="closeTaskEditor"
    )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TaskTable from '~v/task/task-table'
import TaskEditor from './task-editor.vue'
import Scroll from '~m/scroll'
import Task from '~m/task'
import to from 'await-to-js'

export default {
  name: 'task-list',
  components: {
    TaskTable,
    TaskEditor
  },
  mixins: [Scroll, Task],
  data () {
    return {
      taskData: null,
      taskEditorVisible: false,
      filter: null,
      operator: '',
      game: '',
      type: '',
      search: '',
      category: '',
      operatorOptions: [],
      defaultOperators: {
        value: 'all',
        label: this.$t('All operators')
      },
      defaultGames: {
        value: 'all',
        label: this.$t('All games')
      }
    }
  },

  watch: {
    // 監聽網址的變化進行 data 重設
    async '$route' (nv, ov) {
      this.resetSelector()
    },
    allOperators (nv, ov) {
      this.operatorOptions = [
        this.defaultOperators,
        ...nv
      ]
      this.operator = this.$route.query.operator
    }
  },

  computed: {
    // 嵌入營運商的 state 資料
    ...mapGetters('operator', {
      allOperatorsForSelector: 'getAllForSelector'
    }),
    allOperators () {
      return this.allOperatorsForSelector.filter(op => op.actived)
    },

    // 嵌入遊戲的 state 資料
    ...mapGetters('game', {
      allGames: 'getAllForSelector'
    }),

    ...mapGetters('task', {
      taskList: 'getListData',
      taskListLoading: 'getLoadingStatus',
      hasNextTask: 'hasNext'
    }),

    gameList () {
      return [this.defaultGames, ...this.allGames]
    },

    isSystem () {
      return this.$route.params.taskType === 'template'
    },

    taskType () {
      return this.$route.params.taskType
    }
  },
  methods: {
    ...mapActions('operator', { getOperators: 'findAll' }),
    ...mapActions('game', { getAllGames: 'findAll' }),
    ...mapActions('task', {
      findTask: 'find',
      createTask: 'create',
      resetTask: 'reset'
    }),

    updateRoute ({ path, query }) {
      this.$router.push({ path })
      this.$router.replace({ path, query })
    },

    // game input 用
    onGameInputChange (val) {
      let { path, query } = this.$route
      query.game = val
      this.updateRoute({ path, query })
    },

    onOperatorChange (val) {
      let { path, query } = this.$route
      query.operator = val
      this.updateRoute({ path, query })
    },

    // tasktype input 用
    onTaskTypeChange (val) {
      let { path, query } = this.$route
      query.type = val
      this.updateRoute({ path, query })
    },

    onTaskCategoryChange (val) {
      let { path, query } = this.$route
      query.category = val
      this.updateRoute({ path, query })
    },

    // search input 用
    onSearchInputChange (event) {
      clearTimeout(this.delaySearch)
      this.delaySearch = setTimeout(() => {
        let { path, query } = this.$route
        query.search = this.search
        this.updateRoute({ path, query })
      }, 1000)
    },

    // 向 API 取得 data
    // infinity scroll 需綁定
    fetchData (reset = false) {
      let { query } = this.$route
      let { search, operator, game, type, category } = query
      const isSystem = this.isSystem
      operator = operator === 'all' ? '' : operator
      game = game === 'all' ? '' : game
      type = type === 'all' ? '' : type
      category = category === 'all' ? '' : category
      search = search === '' ? null : search

      if (reset) {
        this.resetTask({ operator, search, game, type, category, isSystem })
      }
      if (this.isTaskBusy) return
      this.findTask({ operator, search, game, type, category, isSystem })
    },

    // 依據 route 重設所有過濾條件
    async resetSelector () {
      if (!this.allGames) await this.getAllGames()
      if (!this.operatorOptions.length) {
        await this.getOperators()
        this.operatorOptions = [
          this.defaultOperators,
          ...this.allOperators
        ]
      }
      const { operator, search, game, type, category } = this.$route.query
      this.operator = operator * 1 || 'all'
      this.game = game * 1 || 'all'
      this.type = type || 'all'
      this.category = category || 'all'
      this.search = search || ''

      // console.log(`this.operator!!`, operator)
      // console.log(`this.operatorList!!`, this.operatorList)
      this.fetchData(true)
    },
    showTaskEditor () {
      let operators = []
      let games = []
      if (this.operator === 'all') {
        operators = this.allOperators.map(op => op._id)
      } else if (this.operator) {
        const op = _.find(this.allOperators, { id: this.operator })
        operators = [op._id]
      }

      if (this.game === 'all') {
        games = this.allGames.map(ag => ag._id)
      } else if (this.game) {
        const g = _.find(this.allGames, { id: this.game })
        console.log('g', g)
        games = [g._id]
      }
      this.taskData = {
        type: 'Achievement',
        category: 'AllBetCount',
        name: '',
        description: '',
        key: '',
        targetValue: 0,
        currentValue: 0,
        pointReward: 0,
        gemReward: 0,
        actPointReward: 0,
        laijiCoinReward: 0,
        operators,
        games,
        vipPointReward: 0,
        isSystem: this.isSystem
      }
      this.taskEditorVisible = true
    },
    async onTaskCreate () {
      if (!this.taskData.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closeTaskEditor()
      }
      let data = _.pick(this.taskData, [
        'name',
        'description',
        'operators',
        'games',
        'frequency',
        'type',
        'category',
        'startTime',
        'endTime',
        'targetValue',
        'pointReward',
        'gemReward',
        'actPointReward',
        'laijiCoinReward',
        'goodsReward',
        'avatarReward',
        'avatarFrameReward',
        'vipPointReward',
        'key',
        'currentValue',
        'meta',
        'rewards'
      ])

      let [err] = await to(this.createTask({ data }))

      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Create task error'))
      }
      this.$Message.success(this.$t('Create task successful'))
      this.closeTaskEditor()

      this.fetchData(true)
    },

    closeTaskEditor () {
      this.taskEditorVisible = false
      setTimeout(() => {
        this.taskData = null
      }, 500)
    }
  },
  async mounted () {
    // if (!this.allGames) await this.getAllGames()
    // if (!this.allOperators) await this.getAllOperators()
    // const { search, operator, game, type, category } = this.$route.query

    // this.search = search
    // this.operatorOptions = [
    //   this.defaultOperators,
    //   ...this.allOperators
    // ]

    // this.operator = operator * 1 || 'all'
    // this.game = game * 1 || 'all'
    // this.type = type || 'all'
    // this.category = category || 'all'
    // this.search = search || ''

    // console.log(`this.operator`, this.operator)
    await this.resetSelector()
  },
  beforeDestroy () {
  }
}

</script>

<style lang="sass">
</style>
