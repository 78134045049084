<template lang="pug">
  Modal(
    :mask-closable="true"
    :style="{top: '50px', margin: '0px auto 50px'}"
    :value="visible"
    @on-visible-change="visibleChange"
    @on-cancel="onClose"
  )
    p(slot="header")
      span {{ editType === 'new' ? $t('Add task') : $t('Edit task') }}

    Form.modal-editor(
      :model="formData"
      ref="taskEditorForm"
      :rules="taskEditorRule"
    )
      template(v-if="formData")
        .content-part
          FormItem(prop="type")
            Row
              Col.title(span="9")
                h3 {{$t('Task type')}}
              Col(span="14" offset="1")
                Select(
                  filterable
                  v-model="formData.type"
                  :disabled="!formData.isSystem"
                  :placeholder="$t('Please select task type')"
                )
                  Option(
                    v-for="option in taskTypeOptions"
                    :value="option.value"
                    :key="`type-${option.value}`"
                  ) {{ option.label }}

          FormItem(prop="category")
            Row
              Col.title(span="9")
                h3 {{$t('Task category')}}
              Col(span="14" offset="1")
                Select(
                  filterable
                  v-model="formData.category"
                  :disabled="!formData.isSystem"
                  :placeholder="$t('Please select task category')"
                )
                  Option(
                    v-for="option in taskCategoryOptions"
                    :value="option.value"
                    :key="`category-${option.value}`"
                  ) {{ option.label }}

          FormItem(prop="name")
            Row
              Col.title(span="9")
                h3 {{$t('Task name')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.name"
                  :disabled="!formData.isSystem"
                  :placeholder="$t('Please input task name')"
                )

          FormItem(prop="targetValue")
            Row
              Col.title(span="9")
                h3 {{$t('Task target value')}}
              Col.val(span="14" offset="1")
                Input(
                  number
                  v-model="formData.targetValue"
                  :placeholder="$t('Please input task target value')"
                )

          FormItem(prop="frequency" v-if="isTimeEvent")
            Row
              Col.title(span="9")
                h3 {{$t('Task frequency')}}
              Col.val(span="14" offset="1")
                InputNumber.full-width(
                  :min="0"
                  v-model="formData.frequency"
                  :formatter="value => `${value} ${$t('Minute')}`"
                  :parser="value => value.replace('Minunt','')"
                  :disabled="!formData.isSystem"
                )
          FormItem(v-if="isTimeEvent")
            Row
              Col.title(span="9")
                h3 {{$t('Task time range')}}
              Col.val(span="14" offset="1")
                DatePicker.app-tool.date(
                  v-model="formData.startTime"
                  :options="dateOptions"
                  :placeholder="$t('Select date')"
                  :disabled="!formData.isSystem"
                )
                Icon(type="md-arrow-forward" size="20" style="float: left; margin: 6px 8px 6px 4px;")
                DatePicker.app-tool.date(
                  v-model="formData.endTime"
                  :options="dateOptions"
                  :placeholder="$t('Select date')"
                )

          FormItem(prop="key")
            Row
              Col.title(span="9")
                h3 {{$t('Task key')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.key"
                  :disabled="!formData.isSystem"
                  :placeholder="$t('Please input task key')"
                )
          FormItem(prop="description" v-if="formData.isSystem")
            Row
              Col.title(span="9")
                h3 {{$t('Task description')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.description"
                  type="textarea"
                  :rows="4"
                  :placeholder="$t('Please input task description')"
                )

          FormItem(prop="currentValue"  v-if="!formData.isSystem")
            Row
              Col.title(span="9")
                h3 {{$t('Task current value')}}
              Col.val(span="14" offset="1")
                Input(
                  number
                  v-model="formData.currentValue"
                  :placeholder="$t('Please input task current value')"
                )

          FormItem(prop="status" v-if="!formData.isSystem")
            Row
              Col.title(span="9")
                h3 {{$t('Task status')}}
              Col(span="14" offset="1")
                Select(
                  filterable
                  v-model="formData.status"
                  :placeholder="$t('Please select task status')"
                )
                  Option(
                    v-for="option in statusList"
                    :value="option"
                    :key="`status-${option}`"
                  ) {{ $t(option) }}

        .content-part
          Divider(orientation="left")
            Icon(
              type="logo-usd",
              style="margin-right: 6px"
            )
            span {{ $t('Task reward setting') }}
          FormItem(prop="pointReward")
            Row
              Col.title(span="9")
                h3 {{$t('Point reward')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.pointReward"
                  :disabled="!formData.isSystem"
                  :placeholder="$t('Please input task point reward')"
                )
          FormItem(prop="gemReward")
            Row
              Col.title(span="9")
                h3 {{$t('Gem reward')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.gemReward"
                  :disabled="!formData.isSystem"
                  :placeholder="$t('Please input task gem reward')"
                )

          FormItem(prop="actPointReward")
            Row
              Col.title(span="9")
                h3 {{$t('ActPoint reward')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.actPointReward"
                  :disabled="!formData.isSystem"
                  :placeholder="$t('Please input task actPoint reward')"
                )

          FormItem(prop="laijiCoinReward")
            Row
              Col.title(span="9")
                h3 {{$t('LaijiCoin reward')}}
              Col.val(span="14" offset="1")
                Input(
                  v-model="formData.laijiCoinReward"
                  :disabled="!formData.isSystem"
                  :placeholder="$t('Please input task laijiCoin reward')"
                )

          FormItem(prop="vipPointReward")
            Row
              Col.title(span="9")
                h3 {{$t('Vip point reward')}}
              Col(span="14" offset="1")
                Input(
                  v-model="formData.vipPointReward"
                  :disabled="!formData.isSystem"
                  :placeholder="$t('Please input Vip point')"
                )

          FormItem(prop="goodsReward")
            Row
              Col.title(span="9")
                h3 {{$t('Goods reward')}}
              Col(span="14" offset="1")
                Select(
                  multiple
                  filterable
                  v-model="formData.goodsReward"
                  :disabled="!formData.isSystem"
                  :placeholder="$t('Please select task good reward')"
                )
                  Option(
                    v-for="option in goodsList"
                    :value="option._id"
                    :key="option._id"
                  ) {{option.name}}

          FormItem(prop="avatarReward")
            Row
              Col.title(span="9")
                h3 {{$t('Avatar reward')}}
              Col(span="14" offset="1")
                Select(
                  multiple
                  filterable
                  v-model="formData.avatarReward"
                  :disabled="!formData.isSystem"
                  :placeholder="$t('Please select avatar')"
                )
                  Option(
                    v-for="option in avatarList"
                    :value="option"
                    :key="`avatar-${option}`"
                    :label="$t('Aavtar No. {number}', {number: option})"
                  )
                    Avatar(
                      :src="getAvatar(option)"
                      shape="square"
                    )
                    span.avatar {{ $t('Aavtar No. {number}', { number: option }) }}
          FormItem(prop="avatarFrameReward")
            Row
              Col.title(span="9")
                h3 {{$t('Avatar frame reward')}}
              Col(span="14" offset="1")
                Select(
                  multiple
                  filterable
                  v-model="formData.avatarFrameReward"
                  :disabled="!formData.isSystem"
                  :placeholder="$t('Please select avatar frame')"
                )
                  Option(
                    v-for="option in avatarFrame"
                    :value="option"
                    :key="`frame-${option}`"
                    :label="$t('Aavtar frame No. {number}', {number: option})"
                  )
                    Avatar(
                      :src="getAvatarFrame(option)"
                      shape="square"
                    )
                    span.avatar {{ $t('Aavtar frame No. {number}', { number: option }) }}
          FormItem(prop="rewards")
            Row
              Col.title(span="9")
                h3 {{$t('Other rewards')}}
              Col(span="14" offset="1")
                Input(
                  v-model="formData.rewards"
                  type="textarea"
                  :rows="4"
                  :placeholder="$t('Please input task other rewards in JSON')"
                )

          FormItem(prop="meta")
            Row
              Col.title(span="9")
                h3 {{$t('meta data')}}
              Col(span="14" offset="1")
                Input(
                  v-model="formData.meta"
                  type="textarea"
                  :rows="4"
                )
        .content-part
          Divider(orientation="left")
              Icon(
                type="md-create",
                style="margin-right: 6px"
              )
              span {{ $t('Task target audience') }}

          FormItem(prop="operators")
            Row
              Col.title(span="9")
                h3 {{$t('Task operators')}}
              Col(span="14" offset="1")
                Select(
                  multiple
                  v-model="formData.operators"
                  :disabled="!formData.isSystem"
                  :placeholder="$t('Please select task operators')"
                )
                  Option(
                    v-for="item in operators"
                    :value="item._id"
                    :key="item._id"
                  ) {{ item.fullname }}
            Row(style="padding-top: 0px;" v-if="formData.isSystem")
              Col(span="24")
                Button.app-tool-right(
                  type="success",
                  @click="toggleAllOperators"
                ) {{$t('Select all')}}

          FormItem(prop="games")
            Row
              Col.title(span="9")
                h3 {{$t('Task game')}}
              Col(span="14" offset="1")
                Select(
                  v-model="formData.games"
                  :placeholder="$t('Please select task games')"
                  :disabled="!formData.isSystem"
                  multiple
                )
                  Option(
                    v-for="item in games"
                    :value="item._id"
                    :key="item._id"
                  ) {{ item.name }}

            Row(style="padding-top: 0px;" v-if="formData.isSystem")
              Col(span="24")
                Button.app-tool-right(
                  type="success",
                  @click="toggleAllGames"
                ) {{$t('Select all')}}

    .footer(slot='footer')
      Button(
        icon="md-close"
        @click="onClose"
        :disabled="isUpdating"
      ) {{ $t('Cancel') }}
      Button(
        icon="md-checkmark"
        type="primary"
        @click="handleSubmit"
        :loading="isUpdating"
      ) {{ $t('Confirm') }}
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Thumbnail from '~m/thumbnail'
import Time from '~m/time'
import Task from '~m/task'

export default {
  name: 'task-editor',
  props: {
    formData: Object,
    editType: String,
    onClose: Function,
    onUpdate: Function,
    visible: Boolean
  },
  mixins: [Thumbnail, Time, Task],
  data () {
    return {
      isUpdating: false,
      avatarList: [],
      avatarFrame: []
    }
  },
  watch: {
    formData: {
      handler (nv, ov) {
        if (!this.formData) return
        if (this.formData && this.formData.isReady && !this.formData.isDirty) {
          this.formData.isDirty = this.formData.isReady && !this.formData.isDirty
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('operator', { operators: 'getAllForSelector' }),
    ...mapGetters('game', { games: 'getAllForSelector' }),
    ...mapGetters('goods', { goodsList: 'getAllForSelector' }),

    isTimeEvent () {
      return ['CountDownReward'].includes(this.formData.category) || ['DateRage'].includes(this.formData.type)
    },

    taskEditorRule () {
      return {
        name: [{ required: true, message: this.$t('Please input task name'), trigger: 'blur' }],
        targetValue: [{ required: true, validator: this.verifyNumber, trigger: 'blur' }],
        category: [{ required: true, validator: this.verifySelector('category'), trigger: 'change' }],
        type: [{ required: true, validator: this.verifySelector('type'), trigger: 'change' }]
      }
    }
  },
  methods: {
    ...mapActions('goods', { getGoods: 'findAll' }),
    ...mapActions('game', { getGames: 'findAll' }),
    ...mapActions('operator', { getOperators: 'findAll' }),
    async visibleChange (boolean) {
      if (!boolean) {
        this.isUpdating = false
        return this.onClose()
      }
      this.formData.isReady = true
      // if (!_.size(this.formData.operators)) this.formData.operators = [defaultOperator]
      // if (!_.size(this.formData.games)) this.formData.games = [defaultGame]
    },
    toggleAllGames () {
      // this.$nextTick(() => {
      //   this.formData.games = this.games.map(g => g._id)
      // })
      if (this.formData.games.length) {
        this.formData.games = []
      } else {
        this.formData.games = this.games.map(g => g._id)
      }
    },
    toggleAllOperators () {
      // this.$nextTick(() => {
      //   this.formData.games = this.games.map(g => g._id)
      // })
      if (this.formData.operators.length) {
        this.formData.operators = []
      } else {
        this.formData.operators = this.operators.map(g => g._id)
      }
    },
    verifySelector (field) {
      return (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.$t(`Please select task ${field}`)))
        }
        callback()
      }
    },
    verifyNumber (rule, value, callback) {
      if (!value && value * 1 !== 0) {
        return callback(new Error(this.$t(`This field is required`)))
      } else if (!_.isNumber(value) && value * 1 !== 0) {
        return callback(new Error(this.$t(`Input number`)))
      }
      callback()
    },
    selectAllAvatar () {
      if (this.formData.avatarReward.length < this.avatarList.length) {
        this.formData.avatarReward = []
        this.avatarList.map(item => {
          this.formData.avatarReward.push(item)
        })
      } else {
        this.formData.avatarReward = []
      }
    },
    selectGame (id) {
      for (let item of this.games) {
        if (item._id === id) {
          this.formData.gameId = item.id
        }
      }
    },
    handleSubmit () {
      this.$refs.taskEditorForm.validate(valid => {
        if (!valid) return false
        this.isUpdating = true
        this.onUpdate()
      })
    }
  },
  async mounted () {
    // 頭像 array
    if (!this.avatarList.length) {
      for (let i = 0; i < 80; i++) {
        this.avatarList.push(i)
      }
    }
    // 頭框 array
    if (!this.avatarFrame.length) {
      for (let i = 0; i < 19; i++) {
        this.avatarFrame.push(i)
      }
    }

    if (!_.size(this.games)) await this.getGames()
    if (!_.size(this.goodsList)) await this.getGoods()
    if (!_.size(this.operators)) await this.getOperators()
  }
}
</script>
<style lang="sass">
.full-width
  width: 100%
.avatar
  padding: 0 10px
</style>
