<template lang="pug">
div
  .table
    AppTable.app-table(:columns="columns" :data="listData" border)
      template(slot-scope="{ row, index }" slot="createdAt")
        span {{ timeFormat(row.createdAt) }}
      template(slot-scope="{ row, index }" slot="operators")
        span {{ getOperatorName(row.operators) }}
      template(slot-scope="{ row, index }" slot="name")
        Avatar(v-if="row.games.length && !isSystem" :src="getGameThumb(row.games[0], userInfo)", shape="square", size='small', :style="{marginRight: '8px'}")
        span {{ row.name }}
      template(slot-scope="{ row, index }" slot="fullname" v-if="!isSystem")
        span {{ row.user.fullname }}
      template(slot-scope="{ row, index }" slot="type")
        span {{ $t(`taskType.${row.type}`) }}
      template(slot-scope="{ row, index }" slot="category")
        span {{ $t(`taskCategory.${row.category}`) }}
      template(slot-scope="{ row, index }" slot="status")
        span(:class="getStatusClass(row.status)") {{ $t(row.status) }}
      template(slot-scope="{ row, index }" slot="actions")
        Button.actions(
          type="success"
          size="small"
          icon="md-create"
          @click="showTaskEditor(row)"
        )
  TaskEditor(
    :form-data="taskData"
    edit-type="edit"
    :visible="taskEditorVisible"
    :on-update="onTaskUpdate"
    :on-close="closeTaskEditor"
  )
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AppTable from '~c/app-table'
import Time from '~m/time'
import Thumbnail from '~m/thumbnail'
import TaskEditor from './task-editor'
import to from 'await-to-js'

export default {
  name: 'task-table',
  mixins: [ Time, Thumbnail ],
  components: {
    AppTable,
    TaskEditor
  },
  props: {
    listData: Array
  },
  data () {
    return {
      taskData: null,
      taskEditorVisible: false,
      showDeleteWarning: false
    }
  },
  computed: {
    // 嵌入 auth 的 state 資料
    ...mapGetters('auth', [
      'userInfo'
    ]),
    isSystem () {
      return this.$route.params.taskType === 'template'
    },
    columns () {
      let columns = [
        {
          title: 'ID',
          key: 'taskId',
          width: 100,
          align: 'center'
        },
        {
          title: this.$t('Created at'),
          slot: 'createdAt',
          width: 150,
          align: 'center',
          ellipsis: true
        },
        {
          title: this.$t('Operator'),
          slot: 'operators',
          align: 'center'
        },
        {
          title: this.$t('Task name'),
          slot: 'name',
          align: 'center'
        },
        {
          title: this.$t('Task description'),
          key: 'description',
          remove: !this.isSystem,
          align: 'center'
        },
        {
          title: this.$t('User'),
          slot: 'fullname',
          remove: this.isSystem,
          width: 150,
          align: 'center'
        },
        {
          title: this.$t('Task type'),
          slot: 'type',
          width: 130,
          align: 'center'
        },
        {
          title: this.$t('Task category'),
          slot: 'category',
          width: 150,
          align: 'center'
        },
        {
          title: this.$t('Task current value'),
          key: 'currentValue',
          remove: this.isSystem,
          width: 100,
          align: 'center'
        },
        {
          title: this.$t('Task target value'),
          key: 'targetValue',
          width: 100,
          align: 'center'
        },
        {
          title: this.$t('Status'),
          slot: 'status',
          width: 100,
          align: 'center'
        },
        {
          title: this.$t('Actived'),
          width: 100,
          remove: !this.isSystem,
          render: this.renderSwitch,
          align: 'center'
        },
        {
          title: this.$t('Table actions'),
          slot: 'actions',
          width: 90,
          align: 'center'
        }
      ]

      columns = columns.filter(c => c.remove !== true)
      return columns
    }
  },
  methods: {
    ...mapActions('task', {
      findTask: 'find',
      taskUpdate: 'update',
      resetTask: 'reset'
    }),
    showTaskEditor (row) {
      let data = _.cloneDeep(row)
      let games = data.games.map(g => g._id ? g._id : g)
      let operators = data.operators.map(o => o._id ? o._id : o)
      let goodsReward = data.goodsReward.map(g => g._id ? g._id : g)
      this.taskData = {
        ...data,
        rewards: JSON.stringify(data.rewards),
        goodsReward,
        operators,
        games
      }
      this.taskEditorVisible = true
    },
    getOperatorName (operators) {
      let op = operators.map(op => op.fullname)
      return op.join(', ')
    },
    getStatusClass (status) {
      status = status.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
      if (status[0] === '-') status = status.slice(1)
      return status
    },
    async onTaskUpdate () {
      let data = _.pick(this.taskData, [
        'name',
        'description',
        'operators',
        'games',
        'type',
        'frequency',
        'category',
        'startTime',
        'endTime',
        'targetValue',
        'pointReward',
        'gemReward',
        'actPointReward',
        'laijiCoinReward',
        'goodsReward',
        'avatarReward',
        'avatarFrameReward',
        'vipPointReward',
        'key',
        'status',
        'currentValue',
        'meta',
        'rewards'
      ])

      data.rewards = JSON.parse(data.rewards)

      let [err] = await to(this.taskUpdate({ id: this.taskData.taskId, data }))

      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Update task error'))
      }
      this.$Message.success(this.$t('Update task successful'))
      this.closeTaskEditor()
    },
    closeTaskEditor () {
      this.taskEditorVisible = false
      setTimeout(() => {
        this.taskData = null
      }, 500)
    },
    renderSwitch (h, { row }) {
      const { dispatch } = this.$store
      return h('i-switch', {
        props: {
          value: row.actived
        },
        on: {
          input (val) {
            // 延遲執行避免 swith 動畫卡頓
            setTimeout(() => {
              const data = {
                actived: !row.actived
              }
              dispatch('task/update', { id: row.taskId, data })
            }, 200)
          }
        }
      }, [
        h('Icon', { slot: 'open', props: { type: 'md-checkmark' }, trueValue: 'Enabled' }),
        h('Icon', { slot: 'close', props: { type: 'md-close' }, falseValue: 'Disabled' })
      ])
    }
  }
}
</script>
<style lang="sass">
  .in-progress
    color: #ed4014
  .finished
    color: #19be6b
  .received, .ready
    color: #808695
</style>
