<script>
export default {
  data () {
    return {
      statusList: [
        'InProgress',
        'Finished',
        'Received'
      ],

      taskTypeOptions: [
        {
          value: 'all',
          label: this.$t('taskType.All')
        },
        {
          value: 'Achievement', // '個人成就'
          label: this.$t('taskType.Achievement')
        },
        {
          value: 'Mission', // '任務'
          label: this.$t('taskType.Mission')
        },
        {
          value: 'Level', // '個人升級'
          label: this.$t('taskType.Level')
        },
        {
          value: 'Daily', // '每日任務'
          label: this.$t('taskType.Daily')
        },
        {
          value: 'Cycle', // '週期性任務'
          label: this.$t('taskType.Cycle')
        },
        {
          value: 'TimeCount', // '計時任務'
          label: this.$t('taskType.TimeCount')
        },
        {
          value: 'DateRange', // '期間限定'
          label: this.$t('taskType.DateRange')
        },
        {
          value: 'TimeCountWithLogin', // '登入計時任務'
          label: this.$t('taskType.TimeCountWithLogin')
        },
        {
          value: 'Admob', // 廣告任務
          label: this.$t('taskType.Admob')
        }
      ],
      taskCategoryOptions: [
        {
          value: 'all',
          label: this.$t('taskCategory.All')
        },
        {
          // SPIN次數累積任務
          value: 'AllBetCount',
          label: this.$t('taskCategory.AllBetCount')
        },
        {
          // 押碼量累計任務
          value: 'AllBetAmount',
          label: this.$t('taskCategory.AllBetAmount')
        },
        {
          // 贏分累計任務
          value: 'AllWinAmount',
          label: this.$t('taskCategory.AllWinAmount')
        },
        {
          // 當局贏分任務
          value: 'OneWinAmount',
          label: this.$t('taskCategory.OneWinAmount')
        },
        {
          // 累計獲得免費遊戲任務
          value: 'AllFreeGameCount',
          label: this.$t('taskCategory.AllFreeGameCount')
        },
        {
          // 使用Free Game卡次數任務
          value: 'AllFreeCardUsedCount',
          label: this.$t('taskCategory.AllFreeCardUsedCount')
        },
        {
          // 主遊戲獲得BIG WIN次數任務
          value: 'MainGameBigWinCount',
          label: this.$t('taskCategory.MainGameBigWinCount')
        },
        {
          // 主遊戲獲得MEGA WIN次數任務
          value: 'MainGameMegaWinCount',
          label: this.$t('taskCategory.MainGameMegaWinCount')
        },
        {
          // 主遊戲獲得SUPER WIN次數任務
          value: 'MainGameSuperWinCount',
          label: this.$t('taskCategory.MainGameSuperWinCount')
        },
        {
          // 副遊戲獲得BIG WIN次數任務
          value: 'FreeGameBigWinCount',
          label: this.$t('taskCategory.FreeGameBigWinCount')
        },
        {
          // 副遊戲獲得MEGA WIN次數任務
          value: 'FreeGameMegaWinCount',
          label: this.$t('taskCategory.FreeGameMegaWinCount')
        },
        {
          // 副遊戲獲得SUPER WIN次數任務
          value: 'FreeGameSuperWinCount',
          label: this.$t('taskCategory.FreeGameSuperWinCount')
        },
        {
          // 充值總額任務
          value: 'AllCashInAmount',
          label: this.$t('taskCategory.AllCashInAmount')
        },
        {
          // 充值次數任務
          value: 'AllCashInCount',
          label: this.$t('taskCategory.AllCashInCount')
        },
        {
          // 每日登入任務
          value: 'DailyLogin',
          label: this.$t('taskCategory.DailyLogin')
        },
        {
          // 每日排行榜任務
          value: 'DailyRank',
          label: this.$t('taskCategory.DailyRank')
        },
        {
          // 綁訂帳號任務
          value: 'SocialAuth',
          label: this.$t('taskCategory.SocialAuth')
        },
        {
          // 好友總數
          value: 'AllFriendsCount',
          label: this.$t('taskCategory.AllFriendsCount')
        },
        {
          // 不重複聊天人數
          value: 'AllDifferentChatCount',
          label: this.$t('taskCategory.AllDifferentChatCount')
        },
        {
          // VIP升級
          value: 'VIPLevelUp',
          label: this.$t('taskCategory.VIPLevelUp')
        },
        {
          // 帳號升級
          value: 'AccountLevelUp',
          label: this.$t('taskCategory.AccountLevelUp')
        },
        {
          // 指定計時任務
          value: 'TimeRangeReward',
          label: this.$t('taskCategory.TimeRangeReward')
        },
        {
          // 指定上線獎勵累計次數
          value: 'TimeRangeRewardCount',
          label: this.$t('taskCategory.TimeRangeRewardCount')
        },
        {
          // 定時領幣
          value: 'CountDownReward',
          label: this.$t('taskCategory.CountDownReward')
        },
        {
          // 累計定時領幣
          value: 'CountDownRewardCount',
          label: this.$t('taskCategory.CountDownRewardCount')
        },
        {
          // 累計連續登入
          value: 'ContinueLogin',
          label: this.$t('taskCategory.ContinueLogin')
        },
        {
          // 送出邀請好友達成數
          value: 'SenderSuccessCount',
          label: this.$t('taskCategory.SenderSuccessCount')
        },
        {
          // 已加入好友遊玩局數
          value: 'InviteePlayCount',
          label: this.$t('taskCategory.InviteePlayCount')
        },
        {
          // 被邀請者接受加入獎勵
          value: 'InviteeJoinReward',
          label: this.$t('taskCategory.InviteeJoinReward')
        },
        {
          // 牌型將勵
          value: 'RankReward',
          label: this.$t('taskCategory.RankReward')
        },
        {
          // 遊戲局數
          value: 'PlayCount',
          label: this.$t('taskCategory.PlayCount')
        },
        {
          // 頻道邀請數任務
          value: 'ChannelInviteCount',
          label: this.$t('taskCategory.ChannelInviteCount')
        },
        // 台數累積任務
        {
          value: 'TableCount',
          label: this.$t('taskCategory.TableCount')
        },
        // 連續胡牌
        {
          value: 'ContinueWin',
          label: this.$t('taskCategory.ContinueWin')
        },
        // 累積自摸數
        {
          value: 'SelfHuCount',
          label: this.$t('taskCategory.SelfHuCount')
        },
        // 累積胡牌數
        {
          value: 'HuCount',
          label: this.$t('taskCategory.HuCount')
        },
        // 每日的總冠軍
        {
          value: 'DayChampion',
          label: this.$t('taskCategory.DayChampion')
        },
        // 每週的總冠軍
        {
          value: 'WeekChampion',
          label: this.$t('taskCategory.WeekChampion')
        },
        // 每月的總冠軍
        {
          value: 'MonthChampion',
          label: this.$t('taskCategory.MonthChampion')
        },
        // 累計放槍次數
        {
          value: 'GunCount',
          label: this.$t('taskCategory.GunCount')
        },
        {
          // 圈數累計任務
          value: 'RoundCount',
          label: this.$t('taskCategory.RoundCount')
        },
        {
          // 復活
          value: 'Revival',
          label: this.$t('taskCategory.Revival')
        },
        {
          // 觀看遊戲回放次數
          value: 'ReplayGame',
          label: this.$t('taskCategory.ReplayGame')
        },
        {
          // 頭銜專有任務
          value: 'TitleMission',
          label: this.$t('taskCategory.TitleMission')
        },
        {
          // 連莊任務
          value: 'RemainBanker',
          label: this.$t('taskCategory.RemainBanker')
        },
        {
          // 廣告任務
          value: 'Admob',
          label: this.$t('taskCategory.Admob')
        }
      ]
    }
  }
}
</script>
